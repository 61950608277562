.home-screen {
    position: relative;
    background-image: url('../images/showcase.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    opacity: 0.7;
  }
  
  .fixed-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f8f7f1;
    z-index: 1000;
  }
  
  
  /* Pseudo-element for the overlay */
  .home-screen::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    pointer-events: none;
  }

/* Add keyframe animations */
@keyframes animateFromTop {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes animateFromBottom {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes animateFromRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  /* Keyframes for the walking animation */
@keyframes walkingAnimation {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  /* Apply animation to the image element */
  .animate-from-right .walking-image {
    animation: walkingAnimation 3s ease infinite;
  }
  
  
  /* Apply animations to the respective elements */
  .animate-from-top {
    animation: animateFromTop 2s ease forwards;
  }
  
  .animate-from-bottom {
    animation: animateFromBottom 2s ease forwards;
  }
  
  .animate-from-right {
    animation: animateFromRight 2s ease forwards;
  }
  
  
  
  /* Adjust the z-index to place the content above the overlay */
 
  
  .top-bar-email {
    font-size: 13px;
    font-weight: 500;
    color: #79817c;
  }
  
  .navbar-expand-lg .navbar-nav .nav-link {
    color: #79817c!important;
    letter-spacing: 0.1em;
    font-size: 18px;
    font-weight: 400;
  }
  
  .navbar-expand-lg .navbar-nav .nav-link:hover {
    color: #123821!important;
  }
  
  .top-heading {
    color: #a79132;
    font-size: 20px;
    line-height: 20px;
  }
  
  .main-heading {
    color: #123821;
    font-size: 60px;
    font-weight: 700;
    line-height: 90px;
    margin-top: 5px;
    margin-bottom: 32px;
  }

  .home-btn {
    padding: 15px;
    border-radius: 30px;
    width: 220px;
    color: #123821;
    border: none;
    background-color: white;
    font-weight: bold;
    position: relative;
    overflow: hidden;
    transition: background-color 1s ease;
  }
  
  .home-btn:hover {
    background-color: #a79132;
  }

  .footer {
    position: relative;
    background-image: url('../images/site-footer-shape-1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  
  .footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #123821;
    opacity: 0.9; 
  }
  
  .container {
    position: relative; 
    z-index: 1; 
  }
.footer-main-heading{
    font-size: 20px;
    font-weight: 500;
    color: white;
    line-height: 30px;
}

.footer-links{
    text-decoration: none;
    font-size: 15px;
    font-weight: 400;
    color: #889f91;
    transition: all 500ms ease;
    z-index: 1;
    margin-top: 12px;
}

.footer-links:hover{
    color: white;
}

.copy-right{
    color: #889f91;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
}

.footer-contact-links{
    font-size: 15px;
    font-weight: 400;
    color: #889f91;
    transition: all 500ms ease;
    z-index: 1;
    margin-top: 12px;
    cursor: pointer;
}

.footer-contact-links:hover{
    color: #a79132;
}

.footer-contact{
    font-size: 15px;
    font-weight: 400;
    color: #889f91;
    transition: all 500ms ease;
    z-index: 1;
    margin-top: 12px;
}

.footer-line{
    color: white;
}

.footer-icons{
    height: 40px;
    width: 40px;
    color: white;
    background-color: #1a452b;
    font-size: 14px;
    border-radius: 50%;
    overflow: hidden;
    transition: all 500ms ease;
    z-index: 1;
    cursor: pointer;
}

.footer-icons:hover{
    background-color: white;
    color: #a79132;
}