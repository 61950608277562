.about-heading{
  color: #a79132;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 16px;
  line-height: 16px;
}

/* Add keyframe animation */
/* Add keyframe animation */
@keyframes animateFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Apply animation to the image element */
.animate-from-left {
  animation: animateFromLeft 2s ease;
  animation-fill-mode: forwards;
  opacity: 0; /* Hide the image initially */
}



.about-main-heading{
  color: #123821;
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  letter-spacing: -0.04em;
  margin-top: 5px;
}

.about-text{
  color: #79817c;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
}

.card-heading{
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  color: #123821;
}

.card-1{
  background-color: #f8f7f1;
}
.card-2{
  background-color: #f8f7f1;
}

.about-btn{
  background-color: #a79132;
  color: white;
  padding: 15px;
  width: 220px;
  border: none;
  border-radius: 30px;
  transition: background-color 1s ease;
}

.about-btn:hover{
  background-color: #123821;
}